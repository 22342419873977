/* GMAPS */
function GMap(element,lat,lng,infoContent,base,preventFont){ /* target id | lattitude | longitude | html content for infoWindow | base path | prefent google from appending Roboto font */
	if(preventFont = true){
	  /* ROBOTO FONT FIX!!! */
		var head = document.getElementsByTagName('head')[0];
		var insertBefore = head.insertBefore;
		head.insertBefore = function (newElement, referenceElement) {
		  if (newElement.href && (newElement.href.indexOf('http://fonts.googleapis.com/css?family=Roboto') === 0 || newElement.href.indexOf('https://fonts.googleapis.com/css?family=Roboto') === 0)) {
		    //console.info('Prevented Roboto from loading!');
		    return;
		  }
		  insertBefore.call(head, newElement, referenceElement);
		};
		/* /ROBOTO FONT FIX */
	}
	$(function(){
		var map = new GMaps({
		  div: element,
		  lat: lat,
		  lng: lng
		  //clickableLabels: false,
		  //scrollwheel: false,
		  //draggable: false,
		  //disableDefaultUI: true
		});
		map.addMarker({
				lat: lat,
				lng: lng,
				icon: base+"/img/map_pin.png",   
				infoWindow: {
					content: infoContent
				}        
		}); 
		$(window).resize(function(){
			map.refresh();
			map.setCenter(lat,lng);
		}); 
		//(map.markers[0].infoWindow).open(map.map,map.markers[0]); // used to open info window manually
		//(map.markers[0].infoWindow).close(map.map,map.markers[0]) // close infoWindow
	});
}	

$(function(){

// Scrolltop button
$(window).scroll(function () { 
  if ($(window).scrollTop() > 301) {
		$('#btn-up').removeClass('disabled');
  }
  if ($(window).scrollTop() < 300) {
    $('#btn-up').addClass('disabled');
  }
});

/* Swipebox */
$('.swipebox').swipebox({
	hideBarsDelay : 5000, // delay before hiding bars on desktop
	loopAtEnd: true // true will return to the first image after the last image is reached
});

// SEARCH BUTTON ANIMATIONS
$('#search-trigger').click(function(e){
	e.preventDefault();
	$('#header-search').toggleClass('active');
});
$('#header-search').click(function(e) {
	e.stopPropagation();
});
$(document).click(function() {
	$('#header-search.active').toggleClass('active');
});

/* HP slider */
$('.slider > div').bxSlider({
	auto: true,
	pause: 7000,
	speed:800,
	adaptiveHeight: false,
	//mode: 'fade',
	controls: true,
	pager:false
});

/* HP slider */
$('.product-slider .items').bxSlider({
	auto: true,
	pause: 7000,
	speed:800,
	adaptiveHeight: false,
	//mode: 'fade',
	minSlides: 1,
	maxSlides: 4,
	moveSlides: 4,
	slideWidth: 285,
	controls: true,
	pager:false
});

/* Product slider */
$('#prod-images .items').bxSlider({
	speed:800,
	adaptiveHeight: false,
	controls: true,
	pager:false
});

/* btn up animation */
$('#btn-up').click(function(e){
  e.preventDefault();
  $('body,html').animate({scrollTop:0}, 500);    
});

/* alert msg close btn */
$('.alert .close').click(function(){
	$('.alert').fadeOut();
});

});
